<template>
  <div style="background-color: rgb(247, 247, 247); min-height: 100vh">
    <el-card>
      <div style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
        <div style="
            font-size: 20px;
            font-weight: 700;
            width: 100px;
            height: 30px;
            padding-left: 10px;
            border-radius: 1px;
            line-height: 28px;
            border-left: 5px solid #fb6638;
          ">
          审核列表
        </div>
        <div>
          <span style="
              padding-right: 12px;
              color: #fb6638;
              font-size: 18px;
              line-height: 28px;
            ">点击切换审核状态，默认待审核</span>
          <el-select v-model="status" placeholder="请选择" @change="selectChange">
            <el-option v-for="(item, index) in options" :key="index" :label="item.lebal" :value="item.status">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-card>
    <el-button type="primary" style="float: right; margin-bottom: 20px" @click="refsh">刷新表格</el-button>

    <div class="tab">
      <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
        ref="list" class="emp_table" v-if="list">
        <el-table-column align="center" prop="companyName" label="企业名称" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="contractNo" label="合同编号" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="title" label="合同标题" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="contractType" label="合同类别" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="orderNum" label="订单数量" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="contractPrice" label="订单价格 单位：元" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="signPerson" label="签订人姓名" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="signType" label="	签订人类型" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="signDate" label="签订时间" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="examDate" label="审核时间" :show-overflow-tooltip="true" />

        <el-table-column align="center" prop="tel" label="联系电话" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="stage" label="当前阶段">
          <template slot-scope="scope">
            {{ scope.row.stage == 1 ? '农牧业局' : '市场监管局' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="合同状态">
          <template slot-scope="scope">
            {{ scope.row.status | sh }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="success" v-if="status==200" size="small" @click="agree(scope.row)">
              合同审核
            </el-button>
            <el-button type="primary" v-if="status!=200" size="small" @click="agree(scope.row)">
              审核详情
            </el-button>


          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
        @pagination="pageList" />
    </div>

  </div>
</template>

<script>
import {
  pageList,

} from "../../request/http";
export default {
  data() {
    return {



      showDialog: false,
      list: "",
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },

      status: 200,

      options: [

        {
          lebal: "待审核",
          status: 200,
        },
        {
          lebal: "审核中",
          status: 201,
        },

        {
          lebal: "通过",
          status: 202,
        },
        {
          lebal: "驳回",
          status: 203,
        },
      ],

    };
  },
  created() {
    this.pageList(200);
  },
  mounted() { },
  methods: {

    refsh() {
      location.reload();
    },

    selectChange(value) {
      console.log(value);
      this.status = value;
      this.queryParams.pageNum = 1


      this.pageList(this.status);
    },

    pageList(value) {
      console.log(value);
      if (this.status == 200) {
        pageList({
          pageNum: this.queryParams.pageNum,
          pageSize: 10,
          status: this.status,
        }).then((res) => {
          console.log(res);
          this.list = res.data.data.records;
          this.total = res.data.data.total;
        });
      } else if (this.status == 201) {
        pageList({
          pageNum: this.queryParams.pageNum,
          pageSize: 10,
          status: 201,
        }).then((res) => {
          this.list = res.data.data.records;
          this.total = res.data.data.total;
        });
      } else if (this.status == 202) {
        pageList({
          pageNum: this.queryParams.pageNum,
          pageSize: 10,
          status: 202,
        }).then((res) => {
          this.list = res.data.data.records;
          this.total = res.data.data.total;
        });

      } else if (this.status == 203) {
        pageList({
          pageNum: this.queryParams.pageNum,
          pageSize: 10,
          status: 203,
        }).then((res) => {
          this.list = res.data.data.records;
          this.total = res.data.data.total;
        });
      }

    },

    agree(val) {
      let contractId = val.contractId;
      let recordId = val.recordId;
      let stage = val.stage;
      let status = val.status;
      this.$router.push({
        path: "/review",
        query: { recordId, contractId, stage, status },

      });
    }





  },
};
</script>

<style scoped>
/deep/ .el-select {
  width: 450px;
}

/deep/ .el-card {
  border-radius: 10px;
  margin-bottom: 30px;
}

.minecard {
  box-sizing: border-box;
  width: 320px;
  height: 115px !important;
}

.minecard_item_title {
  color: #909399;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.minecard_item_text {
  padding-top: 10px;
  color: #303133;
  font-size: 33px;
  line-height: 1;
  font-weight: 700;
}

/deep/ .minecard .el-card__body {
  box-sizing: border-box;
  height: 100%;
}
</style>
